import React, { useEffect, useState } from "react";
import Header from "../header";
import Footer from "../footer";
import "../knowledge.css";
import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Nav,
  Pagination,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import axios from 'axios';
import clientConfig from '../../client-config';

const Category = () => {
  const { t, i18n } = useTranslation();
  const [posts, setPosts] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [categoryName, setCategoryName] = useState("");
  const [categoryBanner, setCategoryBanner] = useState("");
  const [tags, setTags] = useState([]);
  const [authToken, setAuthToken] = useState('');
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const { categorySlug } = useParams();

  const getAuthToken = async () => {
    try {
      const response = await axios.post(
        `${clientConfig.siteUrl}/wp-json/jwt-auth/v1/token`,
        {
          username: clientConfig.username,
          password: clientConfig.password,
        }
      );
      return response.data.token;
    } catch (err) {
      console.error("Error fetching auth token:", err);
      return null;
    }
  };

  useEffect(() => {

    const fetchCategoryDetails = async () => {
      try {
        let token = authToken;

        if (!token) {
          token = await getAuthToken();
          if (token) {
            setAuthToken(token);
          }
        }

        const categoryResponse = await axios.get(
          `${clientConfig.siteUrl}/wp-json/wp/v2/categories?slug=${categorySlug}`
        );
        const category = categoryResponse.data[0] || {};
        const categoryId = category.id || null;
        setCategoryName(category.name || "");
        setCategoryBanner(category.acf?.category_banner?.url || "");

        if (categoryId) {
          const postsResponse = await axios.get(
            `${clientConfig.siteUrl}/wp-json/wp/v2/posts?categories=${categoryId}&per_page=10&page=${pageNumber}`,
            {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            }
          );

          const postsWithMedia = await Promise.all(postsResponse.data.map(async (post) => {
            if (post.featured_media) {
              try {
                const mediaResponse = await axios.get(`${clientConfig.siteUrl}/wp-json/wp/v2/media/${post.featured_media}`, {
                  headers: {
                    'Authorization': `Bearer ${token}`
                  }
                });
                post.featured_media_url = mediaResponse.data.source_url;
              } catch (mediaError) {
                console.error(`Error fetching media for post ${post.id}:`, mediaError);
                post.featured_media_url = 'default_image_url';
              }
            } else {
              post.featured_media_url = 'default_image_url';
            }
            return post;
          }));

          setPosts(postsWithMedia);

          const tagsResponse = await axios.get(
            `${clientConfig.siteUrl}/wp-json/wp/v2/tags?categories=${categoryId}`
          );
          setTags(tagsResponse.data);
        }
      } catch (error) {
        console.error("Error fetching category, posts, and tags:", error);
      }
    };

    fetchCategoryDetails();
  }, [categorySlug, pageNumber, authToken]);

    // Function to handle search input changes
    const handleSearchInputChange = (e) => {
      setSearchQuery(e.target.value);
    };
  
    // Function to handle the search action
    const handleSearch = async (e) => {
      e.preventDefault();
  
      try {
        const token = authToken || (await getAuthToken());
        const searchResponse = await axios.get(
          `${clientConfig.siteUrl}/wp-json/wp/v2/posts?search=${searchQuery}&per_page=10`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        const postsWithMedia = await Promise.all(
          searchResponse.data.map(async (post) => {
            if (post.featured_media) {
              try {
                const mediaResponse = await axios.get(
                  `${clientConfig.siteUrl}/wp-json/wp/v2/media/${post.featured_media}`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );
                post.featured_media_url = mediaResponse.data.source_url;
              } catch (mediaError) {
                console.error(
                  `Error fetching media for post ${post.id}:`,
                  mediaError
                );
                post.featured_media_url = "default_image_url";
              }
            } else {
              post.featured_media_url = "default_image_url";
            }
            return post;
          })
        );
  
        setPosts(postsWithMedia);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };

  const truncateText = (text, wordLimit) => {
    if (!text) return '';
    const words = text.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
  };

  const formatCategoryName = (name) => {
    const words = name.split(' ');
    if (words.length > 1) {
      return (
        <span>
          {words[0]} <span style={{ color: '#61422c' }}>{words[1]}</span>
        </span>
      );
    }
    return name;
  };

  return (
    <main className="main">
      <Header />

      <section
        className="sex-councel-hero"
        style={{ backgroundImage: `url(${categoryBanner})` }}
      >
        <Breadcrumb className="cs-breadcum p-6">
          <Breadcrumb.Item className="breadcrumb-link-custom">
            <NavLink to={"/pedsupab"}>{t("Pedsupab")}</NavLink>
          </Breadcrumb.Item>
          {i18n.language === "th" ? (
            posts.length > 0 && posts[0].acf?.category_name_thai ? (
              <Breadcrumb.Item active>
                {posts[0].acf?.category_name_thai}
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item active>{t("No Category")}</Breadcrumb.Item>
            )
          ) : (
            <Breadcrumb.Item active>{categoryName}</Breadcrumb.Item>
          )}
        </Breadcrumb>
        <div className="category-heading">
          {(() => {
            if (i18n.language === "th") {
              if (posts.length > 0 && posts[0].acf?.category_name_thai) {
                return <h1>{posts[0].acf.category_name_thai}</h1>;
              }
            } else {
              return <h1>{formatCategoryName(categoryName)}</h1>;
            }
            return null;
          })()}
        </div>
      </section>

      <div className="kn-dtls-page-content lg:grid grid-cols-[1fr_20rem]">
        <section className="kd-content-card-wrap">
          {posts.map((post) => (
            <NavLink to={`/pedsupab/blog/${post.id}`} key={post.id}>
              <Card className="kd-content-cards m-0 !p-8">
                <Card.Img
                  variant="top"
                  src={post.featured_media_url || "default_image_url"}
                  className="m-0 p-0 !w-96 !h-60"
                />
                <Card.Body className="m-0 lg:!pl-8 pl-0 pb-0">
                  <Card.Title>
                    {i18n.language === "th" ? (
                      <h2
                        dangerouslySetInnerHTML={{
                          __html: post.acf?.post_title_thai,
                        }}
                      />
                    ) : (
                      <h2
                        dangerouslySetInnerHTML={{
                          __html: post.title.rendered,
                        }}
                      />
                    )}
                  </Card.Title>
                  <Card.Text className="card-text">
                    {i18n.language === "th" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: truncateText(
                            post.acf?.post_description_thai,
                            20
                          ),
                        }}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.excerpt.rendered,
                        }}
                      />
                    )}
                  </Card.Text>
                  <Button className="detailsbtn">
                    {i18n.language === "th"
                      ? post.acf?.category_name_thai
                      : categoryName}
                  </Button>
                </Card.Body>
              </Card>
            </NavLink>
          ))}

          {posts.length > 0 && (
            <div className="pagination_wrap">
              <Pagination>
                <Pagination.Prev
                  onClick={() => setPageNumber((prevPage) => prevPage - 1)}
                  disabled={pageNumber === 1}
                />
                <Pagination.Item active>{pageNumber}</Pagination.Item>
                <Pagination.Next
                  onClick={() => setPageNumber((prevPage) => prevPage + 1)}
                />
              </Pagination>
            </div>
          )}
        </section>

        <section className="tag_wrapper !border-none">
          <Container>
            <div className="relative">
              <form onSubmit={handleSearch}>
                  <input
                  className="w-full rounded-md"
                  placeholder="Start typing to search..."
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
                {/* <a className="fa fa-search absolute top-1/2 right-4 transform -translate-y-1/2" /> */}
                <button type="submit" className="fa fa-search absolute top-1/2 right-4 transform -translate-y-1/2">
                </button>
              </form>
            </div>

            <div className="tagcard">
              <Nav
                onSelect={(selectedKey) =>
                  console.log(`selected ${selectedKey}`)
                }
                className="flex flex-col !items-start"
              >
                <h3 className="text-2xl font-bold text-black">{t("TAGS")}</h3>
                <div className="flex flex-wrap gap-2.5 mt-2.5">
                  {tags.map((tag) => (
                    <Nav.Item
                      key={tag.id}
                      className="bg-[#dfdbca] px-2 py-1 rounded-full"
                    >
                      <Nav.Link
                        eventKey={`#${tag.slug}`}
                        className="!text-sm text-black"
                      >
                        {tag.name}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </div>
              </Nav>
            </div>
          </Container>
        </section>
      </div>
      <Footer />
    </main>
  );
};

export default Category;
