import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDistanceToNow } from 'date-fns';

const ArticlesCard = ({ post, categories }) => {
  const { t, i18n } = useTranslation();
  const categoryNames =
    post.category_names ??
    post.categories.map((catId) => categories[catId]).join(", ");

  return (
    <div className="item">
      <Link to={`/pedsupab/blog/${post.id}`} >
        <div className="graphic">
          <img
            src={post.featured_media_url || "default_image_url"} // Ensure your posts have a valid image URL or use a default image URL
            alt={post.title.rendered}
            className="article_image"
          />
        </div>
        <button className="detailsbtn">
          {i18n.language === "th"
            ? post.acf.category_name_thai
            : categoryNames || t("No Category")}
        </button>
        {i18n.language === "th" ? (
          <h3
            className="detailstext"
            dangerouslySetInnerHTML={{ __html: post.acf.post_title_thai }}
          ></h3>
        ) : (
          <h3
            className="detailstext"
            dangerouslySetInnerHTML={{ __html: post.title.rendered }}
          ></h3>
        )}
        <p className="detailspnametext">
          {post.author_name}
          <span className="custom-padding"></span>
          {formatDistanceToNow(new Date(post.date), { addSuffix: true })}
        </p>
      </Link>
    </div>
  );
};

export default ArticlesCard;
